import React from "react";
import { graphql } from "gatsby";
import Layout from "../layouts";
import { RetirementCalculator } from "../components/RetirementCalculator";
import { SalaryCalculator } from "../components/SalaryCalculator";
import Helmet from "react-helmet";
import { NavBar, CardSignup } from "../components";
import Link from "gatsby-link";
import "./post.css";
import { artifacts } from "../utils/icons";

const widgetMap = {
  retirement: <RetirementCalculator />,
  salary: <SalaryCalculator showHeading={false} />,
};

export default function Template({ data, pageContext }) {
  const {
    date,
    title,
    subtitle,
    metaDescription,
    content: {
      childMarkdownRemark: { html },
    },
    featuredImage: {
      fluid: { src },
    },
    metaImage,
    widget,
  } = data.contentfulPost;

  const backgroundImage = `url(${src})`;

  const preview = metaImage.fluid.src;

  return (
    <Layout>
      <Helmet
        title={title}
        meta={[
          {
            name: "title",
            content: title,
          },
          {
            name: "description",
            content: metaDescription,
          },
          {
            name: "og:title",
            content: title,
          },
          {
            name: "og:description",
            content: metaDescription,
          },
          {
            name: "twitter:description",
            content: metaDescription,
          },
          {
            name: "twitter:image",
            content: `https://investmentcalculator.io${preview}`,
          },
          {
            property: "og:image",
            content: `https://investmentcalculator.io${preview}`,
          },
        ]}
      />

      <div className="blog-post-page">
        <NavBar />
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "400px",
            top: "0px",
            zIndex: "-1",
          }}
        >
          {artifacts}
        </div>
        <div className="container">
          <div className="blog-post-container">
            <div className="animate-bg">
              <header
                className="post-layout-header "
                style={{ backgroundImage }}
              >
                <div className="breadcrumbs">
                  <Link className="breadcrumb-item" to="/">
                    Home
                  </Link>
                  <Link className="breadcrumb-item" to="/blog">
                    Blog
                  </Link>
                  <span>Article</span>
                </div>
                <div className="blog-post-intro animate">
                  <h1 className="post-title">{title}</h1>
                  <p className="post-subtitle">{subtitle}</p>
                  <h4 className="post-author">
                    By <span className="tc-purple">Staff</span>
                  </h4>
                  <p className="post-date tc-grey fs-14">{date}</p>
                </div>
              </header>
            </div>
            <div className="blog-layout blog-post-layout">
              <div className="blog-content-wrapper animate">
                {widgetMap[widget] || null}
                <div className="blog-post card">
                  <div
                    className="blog-post-content"
                    dangerouslySetInnerHTML={{ __html: html }}
                  />
                </div>
              </div>
              <div className="blog-post-sidebar animate">
                <CardSignup />
                <div className="card">
                  <h2 className="mb-1">Recent Articles</h2>
                  <ul>
                    {pageContext.posts.map((post, i) => {
                      return (
                        <li key={i} style={{ display: "block" }}>
                          <Link to={post.path}>{post.title}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const postQuery = graphql`
  query($id: String!) {
    contentfulPost(id: { eq: $id }) {
      title
      subtitle
      date(formatString: "MM / DD / YYYY")
      metaDescription
      widget
      featuredImage {
        fluid {
          src
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      metaImage {
        fluid {
          src
        }
      }
    }
  }
`;
